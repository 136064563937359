import { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import { ApplyFloat } from "styled/StylesStyled";
import { getCurrentDay, getLangKey } from "utils/common/locale.util";

function DateTimeText() {
  const langKey = getLangKey() === "nb" ? "nb" : "en-gb";
  const theme = useTheme();

  const [currentDateTime, setCurrentDateTime] = useState<string>(langKey);

  useEffect(() => {
    setCurrentDateTime(langKey);
    const timer = setInterval(() => {
      setCurrentDateTime(langKey);
    }, 10 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [langKey]);
  return (
    <ApplyFloat float="left">
      <div style={{ fontSize: 16, color: theme.palette.neutral[80] }}>
        {getCurrentDay(currentDateTime) ?? ""}
      </div>
    </ApplyFloat>
  );
}
export default DateTimeText;
