import { Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact, {
  HighchartsReactRefObject,
} from "highcharts-react-official";
import moment from "moment";
import { createRef, memo } from "react";
import ResizeDetector from "react-resize-detector";

const Clock = ({
  size,
  time,
  title,
  datetime,
}: {
  size: any;
  time: any;
  title: string;
  datetime: boolean;
}) => {
  if (!time) {
    return null;
  }
  const chartRef = createRef<HighchartsReactRefObject>();
  const options = getPlotOptions(size, toTimeObject(time));
  return (
    <ResizeDetector
      handleWidth
      onResize={() =>
        chartRef.current ? chartRef.current.chart.reflow() : undefined
      }
    >
      <div>
        <Typography align="center">{title}</Typography>
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          constructorType="chart"
          options={options}
        />
        {!datetime ? (
          <Typography variant="h4" align="center">
            {moment(time).isValid() ? moment(time).format("HH:mm") : "-"}
          </Typography>
        ) : (
          <Typography variant="h4" align="center">
            {moment(time).isValid() ? (
              <>
                {moment(time).format("HH:mm")}
                <br />
                {`(${moment(time).format("MMM-D")})`}
              </>
            ) : (
              "-"
            )}
          </Typography>
        )}
      </div>
    </ResizeDetector>
  );
};

export default memo(Clock);

const toTimeObject = (time: any) => ({
  hours: time.getHours(),
  minutes: time.getMinutes(),
  seconds: time.getSeconds(),
});

const getPlotOptions = (height: number, clockTime: any) => {
  const clockHours = clockTime.hours + clockTime.minutes / 60;
  const clockMinutes =
    (clockTime.minutes * 12) / 60 + (clockTime.seconds * 12) / 3600;

  return {
    chart: {
      type: "gauge",
      backgroundColor: "transparent",
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height,
    },

    credits: {
      enabled: false,
    },

    title: {
      text: null,
    },

    pane: {
      size: "110%",
    },

    yAxis: {
      labels: {
        distance: -15,
        style: {
          color: "#aaa",
        },
      },
      min: 0,
      max: 12,
      lineWidth: 0,
      showFirstLabel: false,

      minorTickInterval: "auto",
      minorTickWidth: 1,
      minorTickLength: 2,
      minorTickPosition: "inside",
      minorGridLineWidth: 0,
      minorTickColor: "#777",

      tickInterval: 1,
      tickWidth: 2,
      tickPosition: "inside",
      tickLength: 6,
      tickColor: "#666",
      title: null,
    },

    tooltip: {
      enabled: false,
    },

    series: [
      {
        data: [
          {
            id: "hour",
            y: clockHours,
            dial: {
              radius: "60%",
              baseWidth: 4,
              baseLength: "95%",
              rearLength: 0,
            },
          },
          {
            id: "minute",
            y: clockMinutes,
            dial: {
              baseLength: "95%",
              rearLength: 0,
            },
          },
        ],
        animation: false,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };
};
