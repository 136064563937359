import i18n from "i18n/config";

interface DateTimeFormatOptions {
  localeMatcher?: "lookup" | "best fit";
  weekday?: "long" | "short" | "narrow";
  era?: "long" | "short" | "narrow";
  year?: "numeric" | "2-digit";
  month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
  day?: "numeric" | "2-digit";
  hour?: "numeric" | "2-digit";
  minute?: "numeric" | "2-digit";
  second?: "numeric" | "2-digit";
  timeZoneName?: "long" | "short";
  formatMatcher?: "basic" | "best fit";
  hour12?: boolean;
  timeZone?: string; // this is more complicated than the others, not sure what I expect here
}

export const getCurrentDay = (langKey: string) => {
  const options: DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const today = new Date();
  const currentDateTime = today.toLocaleDateString(langKey, options);
  if (currentDateTime) {
    // to fix norsk first letter capitalize issue
    return (
      currentDateTime.charAt(0).toLocaleUpperCase() + currentDateTime.slice(1)
    );
  }
  return "";
};

export const getLangKey = (): "nb" | "en" => {
  const lang =
    i18n.language === "nb_healthwelfare" ||
    i18n.language === "nb_sleep_research" ||
    i18n.language === "nb_private_customer"
      ? "nb"
      : "en";

  return lang;
};
