import { ArrowBack } from "@mui/icons-material";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { BackButtonStyled, MainTitleStyled } from "styled/CommonStyled";

const ArrowBackStyled = styled(ArrowBack)(() => {
  return {
    float: "left",
    marginRight: 4,
    width: 24,
    height: 24,
  };
});

export const Maintitle = ({
  showBack,
  text,
  textBack = "back",
  onBack,
  sx,
  bg,
  borderbottom,
  iconComponent,
}: {
  showBack: boolean;
  text: string;
  textBack?: string;
  onBack?: () => void;
  sx?: any;
  bg?: "light" | "dark";
  borderbottom?: "light" | "dark";
  iconComponent?: JSX.Element;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const handleOnClick = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };
  return (
    <MainTitleStyled bg={bg} sx={sx} borderbottom={borderbottom}>
      <div> {text} </div>
      {showBack ? (
        <BackButtonStyled onClick={handleOnClick}>
          {iconComponent || <ArrowBackStyled />}
          {textBack ?? t("back")}
        </BackButtonStyled>
      ) : (
        ""
      )}
    </MainTitleStyled>
  );
};

export default Maintitle;
